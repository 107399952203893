import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HtmlPageModel } from '../../models/data/cms/html-page.model';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { QueryListModel } from '../../models/query-list.model';
import { HtmlPageFilterModel } from '../../models/data/cms/filters/html-page-filter.model';
import { QueryResultModel } from '../../models/query-result.model';
import { HttpUtils } from '../../utils/http.utils';

@Injectable({
    providedIn: 'root'
})
export class HtmlPageService {
    constructor(private http: HttpClient) { }
    getById(id: number): Observable<HtmlPageModel> {
        const url = environment.apiUrl + '/api/cms/HtmlPage/' + id.toString();
        return this.http.get<HtmlPageModel>(url);
    }

    getListByQuery(model: QueryListModel<HtmlPageFilterModel> = new QueryListModel<HtmlPageFilterModel>()):
        Observable<QueryResultModel<HtmlPageModel>> {
        const url = environment.apiUrl + '/api/cms/HtmlPage/getlistbyquery';

        return this.http.get<QueryResultModel<HtmlPageModel>>(url, {
            'params': HttpUtils.getQueryListParams(model)
        });
    }

    getActiveList(): Observable<HtmlPageModel[]> {
        const url = environment.apiUrl + '/api/cms/HtmlPage/GetActive';

        return this.http.get<HtmlPageModel[]>(url);
    }

    getItMarketList(): Observable<HtmlPageModel[]> {
        const url = environment.apiUrl + '/api/cms/HtmlPage/GetItMarketPages';

        return this.http.get<HtmlPageModel[]>(url);
    }
}
