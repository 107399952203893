import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NewsModel } from '../../models/data/cms/news.model';
import { environment } from 'src/environments/environment';
import { QueryListModel } from '../../models/query-list.model';
import { NewsFilterModel } from '../../models/data/cms/filters/news-filter.model';
import { QueryResultModel } from '../../models/query-result.model';
import { HttpUtils } from '../../utils/http.utils';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NewsService {
    constructor(private http: HttpClient) { }
    getById(id: number): Observable<NewsModel> {
        const url = environment.apiUrl + '/api/cms/News/' + id.toString();
        return this.http.get<NewsModel>(url);
    }

    getListByQuery(model: QueryListModel<NewsFilterModel> = new QueryListModel<NewsFilterModel>()):
        Observable<QueryResultModel<NewsModel>> {
        const url = environment.apiUrl + '/api/cms/News/getlistbyquery';

        return this.http.get<QueryResultModel<NewsModel>>(url, {
            'params': HttpUtils.getQueryListParams(model)
        });
    }
}
