import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyInternshipModel } from '../../models/data/company/company-internship.model';
import { environment } from 'src/environments/environment';
import { QueryListModel } from '../../models/query-list.model';
import { CompanyInternshipFilterModel } from '../../models/data/company/filters/company-internship-filter.model';
import { QueryResultModel } from '../../models/query-result.model';
import { HttpUtils } from '../../utils/http.utils';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CompanyInternshipService {
    constructor(private http: HttpClient) { }
    getById(id: number): Observable<CompanyInternshipModel> {
        const url = environment.apiUrl + '/api/industry/CompanyInternship/' + id.toString();
        return this.http.get<CompanyInternshipModel>(url);
    }

    getListByQuery(model: QueryListModel<CompanyInternshipFilterModel> = new QueryListModel<CompanyInternshipFilterModel>()):
        Observable<QueryResultModel<CompanyInternshipModel>> {
        const url = environment.apiUrl + '/api/industry/CompanyInternship/getlistbyquery';

        return this.http.get<QueryResultModel<CompanyInternshipModel>>(url, {
            'params': HttpUtils.getQueryListParams(model)
        });
    }

}
