import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryListModel } from 'src/app/core/models/query-list.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QueryResultModel } from 'src/app/core/models/query-result.model';
import { HttpUtils } from 'src/app/core/utils/http.utils';
import { StudyProgramFilterModel } from '../../models/data/education/filters/study-program-filter.model';
import { StudyProgramModel } from '../../models/data/education/study-program.model';

@Injectable({
    providedIn: 'root'
})
export class StudyProgramService {
    constructor(private http: HttpClient) { }
    getById(id: number): Observable<StudyProgramModel> {
        const url = environment.apiUrl + '/api/education/StudyProgram/' + id.toString();
        return this.http.get<StudyProgramModel>(url);
    }

    getListByQuery(model: QueryListModel<StudyProgramFilterModel> = new QueryListModel<StudyProgramFilterModel>()):
        Observable<QueryResultModel<StudyProgramModel>> {
        const url = environment.apiUrl + '/api/education/StudyProgram/getlistbyquery';
        model.sortField='Department';
        model.sortOrder='asc';
        return this.http.get<QueryResultModel<StudyProgramModel>>(url, {
            'params': HttpUtils.getQueryListParams(model)
        });
    }

}
