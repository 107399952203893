import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryListModel } from 'src/app/core/models/query-list.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QueryResultModel } from 'src/app/core/models/query-result.model';
import { HttpUtils } from 'src/app/core/utils/http.utils';
import { CourseModel } from '../../models/data/education/course.model';
import { CourseFilterModel } from '../../models/data/education/filters/course-filter.model';

@Injectable({
    providedIn: 'root'
})
export class CourseService {
    constructor(private http: HttpClient) { }
    getById(id: number): Observable<CourseModel> {
        const url = environment.apiUrl + '/api/education/Course/' + id.toString();
        return this.http.get<CourseModel>(url);
    }

    getListByQuery(model: QueryListModel<CourseFilterModel> = new QueryListModel<CourseFilterModel>()):
        Observable<QueryResultModel<CourseModel>> {
        const url = environment.apiUrl + '/api/education/Course/getlistbyquery';

        return this.http.get<QueryResultModel<CourseModel>>(url, {
            'params': HttpUtils.getQueryListParams(model)
        });
    }

}
