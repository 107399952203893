import { QueryListModel } from '../../models/query-list.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventModel } from '../../models/data/event/event.model';
import { environment } from 'src/environments/environment';
import { EventFilterModel } from '../../models/data/event/filters/event-filter.model';
import { QueryResultModel } from '../../models/query-result.model';
import { HttpUtils } from '../../utils/http.utils';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EventService {
    constructor(private http: HttpClient) { }
    getById(id: number): Observable<EventModel> {
        const url = environment.apiUrl + '/api/events/Event/' + id.toString();
        return this.http.get<EventModel>(url);
    }

    getListByQuery(model: QueryListModel<EventFilterModel> = new QueryListModel<EventFilterModel>()):
        Observable<QueryResultModel<EventModel>> {
        const url = environment.apiUrl + '/api/events/Event/getlistbyquery';

        return this.http.get<QueryResultModel<EventModel>>(url, {
            'params': HttpUtils.getQueryListParams(model)
        });
    }
    getClosestListByQuery(model: QueryListModel<EventFilterModel> = new QueryListModel<EventFilterModel>()):
    Observable<QueryResultModel<EventModel>> {
    const url = environment.apiUrl + '/api/events/Event/getclosestlistbyquery';

    return this.http.get<QueryResultModel<EventModel>>(url, {
        'params': HttpUtils.getQueryListParams(model)
    });
}
}
