import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryListModel } from 'src/app/core/models/query-list.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QueryResultModel } from 'src/app/core/models/query-result.model';
import { HttpUtils } from 'src/app/core/utils/http.utils';
import { CompanyEducationModel } from '../../models/data/company/company-education.model';
import { CompanyEducationFilterModel } from 'src/app/core/models/data/company/filters/company-education-filter.model';

@Injectable({
    providedIn: 'root'
})
export class CompanyEducationService {
    constructor(private http: HttpClient) { }
    getById(id: number): Observable<CompanyEducationModel> {
        const url = environment.apiUrl + '/api/industry/CompanyEducation/' + id.toString();
        return this.http.get<CompanyEducationModel>(url);
    }

    getListByQuery(model: QueryListModel<CompanyEducationFilterModel> = new QueryListModel<CompanyEducationFilterModel>()):
        Observable<QueryResultModel<CompanyEducationModel>> {
        const url = environment.apiUrl + '/api/industry/CompanyEducation/getlistbyquery';

        return this.http.get<QueryResultModel<CompanyEducationModel>>(url, {
            'params': HttpUtils.getQueryListParams(model)
        });
    }

}
