import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SuccessStoryModel } from '../../models/data/admin/success-story.model';
import { environment } from 'src/environments/environment';
import { QueryListModel } from '../../models/query-list.model';
import { SuccessStoryFilterModel } from '../../models/data/admin/filters/success-story-filter.mode';
import { QueryResultModel } from '../../models/query-result.model';
import { HttpUtils } from '../../utils/http.utils';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SuccessStoryService {
    constructor(private http: HttpClient) { }
    getById(id: number): Observable<SuccessStoryModel> {
        const url = environment.apiUrl + '/api/admin/SuccessStory/' + id.toString();
        return this.http.get<SuccessStoryModel>(url);
    }

    getListByQuery(model: QueryListModel<SuccessStoryFilterModel> = new QueryListModel<SuccessStoryFilterModel>()):
        Observable<QueryResultModel<SuccessStoryModel>> {
        const url = environment.apiUrl + '/api/admin/SuccessStory/getlistbyquery';

        return this.http.get<QueryResultModel<SuccessStoryModel>>(url, {
            'params': HttpUtils.getQueryListParams(model)
        });
    }
}
