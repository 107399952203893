import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyJobModel } from '../../models/data/company/company-Job.model';
import { environment } from 'src/environments/environment';
import { QueryListModel } from '../../models/query-list.model';
import { CompanyJobFilterModel } from '../../models/data/company/filters/company-Job-filter.model';
import { QueryResultModel } from '../../models/query-result.model';
import { HttpUtils } from '../../utils/http.utils';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CompanyJobService {
    constructor(private http: HttpClient) { }
    getById(id: number): Observable<CompanyJobModel> {
        const url = environment.apiUrl + '/api/industry/CompanyJob/' + id.toString();
        return this.http.get<CompanyJobModel>(url);
    }

    getListByQuery(model: QueryListModel<CompanyJobFilterModel> = new QueryListModel<CompanyJobFilterModel>()):
        Observable<QueryResultModel<CompanyJobModel>> {
        const url = environment.apiUrl + '/api/industry/CompanyJob/getlistbyquery';

        return this.http.get<QueryResultModel<CompanyJobModel>>(url, {
            'params': HttpUtils.getQueryListParams(model)
        });
    }

}
